import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AutoFocusDirective } from './directives/autofocus.directive';
import { SharingSheetComponent } from './sharing-sheet/sharing-sheet.component';

@NgModule({
    declarations: [
        AutoFocusDirective,
        SharingSheetComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatBadgeModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatGridListModule,
        MatListModule,
        MatIconModule,
        MatBottomSheetModule,
        MatToolbarModule,
    ],
    exports: [
        AutoFocusDirective,
        SharingSheetComponent
    ],
    entryComponents: [
        SharingSheetComponent
    ]
})
export class CoreModule { }
