import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(mod => mod.ComponentsModule),
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule),
    },
    {
        path: 'terminal',
        loadChildren: () => import('./terminal/terminal.module').then(mod => mod.TerminalModule),
    },
    {
        path: 'sportsbook/:bookie',
        loadChildren: () => import('./sportsbook/sportsbook.module').then(mod => mod.SportsbookModule),
    },
    {
        path: ':sport/:country/:league/:searchTerm',
        loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule),
    },
    {
        path: ':sport/:country/:league',
        loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule),
    },
    {
        path: ':searchTerm',
        loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule),
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
