import { Component, ChangeDetectorRef, ViewChild, ElementRef, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { SearchService } from './search/search.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from './core/config.service';

import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    buildnumber = "1.0";

    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;

    links = [{ title: 'Search', route: 'search' }, { title: 'Ask', route: 'terminal' }];

    master = false;
    showFilter: boolean;
    showSlip: boolean;
    oddsFormat: string;
    config: any;

    inputBounce = new Subject<string>();

    query: string = null;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public auth: AngularFireAuth,
        public searchService: SearchService,
        public dialog: MatDialog,
        private activedRouter: ActivatedRoute,
        private configService: ConfigService,
    ) {
        this.mobileQuery = media.matchMedia('screen and (max-width: 959px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.activedRouter.queryParams.subscribe(params => {
            if (params.q) {
                this.query = params.q;
            }
        });

        this.searchService.onOddsFormatChanged.subscribe(foramt => {
            this.oddsFormat = foramt;
        });

        this.configService.onConfigChanged.subscribe(config => this.config = config);
    }

    async ngOnInit() {
        const user = await this.auth.signInAnonymously();

        console.log('user', user)
    }

    bannerChanged(ev: any) {
        console.log('bannerChanged', ev)
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
}
