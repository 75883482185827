import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SearchService implements Resolve<any>, OnDestroy {
    query;
    userquery;
    selections: any[] = [];
    from = 0;
    size = 25;

    private _oddsFormat: 'dec' | 'frac' = 'frac';

    private _unsubscribeAll = new Subject();

    private _refinements = new Map<string, string>();

    public get refinements(): Map<string, string> {
        return this._refinements;
    }

    public onMenuClicked = new Subject();

    onSelectionsChanged: BehaviorSubject<any>;
    onHitsChanged: BehaviorSubject<any>;
    onRefinementsChanged: BehaviorSubject<any>;
    onOddsFormatChanged: BehaviorSubject<any>;

    constructor(
        private http: HttpClient
    ) {
        this.onSelectionsChanged = new BehaviorSubject(null);
        this.onHitsChanged = new BehaviorSubject({});
        this.onRefinementsChanged = new BehaviorSubject({});
        this.onOddsFormatChanged = new BehaviorSubject('frac');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        if (route.params.searchTerm) {
            this.userquery = route.params.searchTerm.replace(/([^0-9])(-)/g, '$1 ');
            this.setQuery(route.params.searchTerm.replace(/([^0-9])(-)/g, '$1 '));

        } else if (route.params.league) {
            this.setQuery(route.params.league);

        } else if (route.queryParams.q) {
            this.setQuery(route.queryParams.q);

        } else {
            // this.setQuery('');
        }
    }

    public openMenu() {
        this.onMenuClicked.next();
    }

    showMore() {
        this.size += 25;

        this.setQuery(this.userquery);
    }

    toggleOddsFormat() {
        this._oddsFormat = this._oddsFormat === 'dec' ? 'frac' : 'dec';

        this.onOddsFormatChanged.next(this._oddsFormat);
    }

    getAutoComplete(q) {
        this.http.get(`${environment.elastic.api}/getSuggestions/${q}`)
            .subscribe((resp: any) => {
                this.onHitsChanged.next({ suggestions: resp, resetPage: false });

            }, (err) => {
                console.log('getSuggestions err', err);

            });

    }

    setQuery(q, resetPage: boolean = false) {
        if (!q) { return; }

        if (resetPage) { this.size = 25; }

        this.userquery = q;

        this.http.get(`${environment.elastic.api}/getRunners/${q}/${this.from}/${this.size}`)
            .subscribe((resp: any) => {
                resp.hits.forEach(hit => {
                    hit.odds = hit.odds.sort((a: { decimal: number; }, b: { decimal: number; }) => {
                        if (a.decimal > b.decimal) { return -1; }
                        if (a.decimal < b.decimal) { return 1; }
                        return 0;
                    });
                });

                this.onHitsChanged.next({ ...resp, resetPage });

            }, (err) => {
                console.log('getRunners err', err);

            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
    }
}