import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'sharing-sheet',
    templateUrl: './sharing-sheet.component.html',
    styleUrls: ['./sharing-sheet.component.scss']
})
export class SharingSheetComponent implements OnInit {
    constructor(
        private _bottomSheetRef: MatBottomSheetRef<SharingSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
        ) { }

    ngOnInit() {
    }

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
    }

    openWhatsapp() {
        return 'https://api.whatsapp.com/send?text=https://www.searchtheodds.com/search?q=' + encodeURI(this.data.hit._source.name);
    }

    openFacebook() {
        window.open('http://www.facebook.com/dialog/feed?app_id=255839424901396&redirect_uri=https%3A%2F%2Fwww.searchtheodds.com&link=https://www.searchtheodds.com/search?q=' + this.data.hit._source.name + '&display=popup', '_blank', 'toolbar=no,scrollbars=yes,resizable=no,fullscreen=no,top=50,left=50,width=555,height=615').opener = null;
    }

    openTwitter() {
        window.open('https://twitter.com/intent/tweet?text=Search the Odds - ' + encodeURI(this.data.hit._source.name) + '&url=https://www.searchtheodds.com/search?q=' + this.data.hit._source.name, '_blank', 'toolbar=no,scrollbars=yes,resizable=no,fullscreen=no,top=50,left=50,width=550,height=250').opener = null;
    }
}
