import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    public onConfigChanged: BehaviorSubject<any>;
    public onLocationChanged: BehaviorSubject<any>;

    public get config(): any {
        return this.onConfigChanged.getValue();
    }

    constructor(
        private http: HttpClient,

    ) {
        this.onConfigChanged = new BehaviorSubject({
            navbar: {
                visible: false,
                menu: {
                    visible: false
                },
                logo: {
                    visible: false
                }
            },
            footer: {
                visible: false,
                fixed: {
                    visible: false
                }
            },

        });

        this.onLocationChanged = new BehaviorSubject({});

        this.http.get(`${environment.elastic.api}/getGeoLocation`)
            .subscribe((data: any) => {
                // console.log('geolocation', data);

                this.onLocationChanged.next(data);

            }, (err) => {
                console.log('geolocation err', err);

            });
    }

    setConfig(config) {
        this.onConfigChanged.next(config);
    }
}
