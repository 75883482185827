// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  buildnumber: '2.2.66',
  algolia: {
    appId: 'U7H5CPZOSC',
    searchKey: '07f0b1a4614cc7064a7b3b7637ad61be'
  },
  elastic: {
    host: "https://searchonly:Vpj7RFJwjFqU8Xe@4312313c1cbc4eb9985415093421efb7.europe-west1.gcp.cloud.es.io:9243",
    api: "https://europe-west2-betdar-prod.cloudfunctions.net/api/v1"
  },
  olddevelastic: {
    host: "https://searchonly:Vpj7RFJwjFqU8Xe@68d6ddc4b3cc49e8a72a352913259d5f.europe-west2.gcp.elastic-cloud.com:9243",
    api: "https://europe-west2-betdar-dev.cloudfunctions.net/api/v1"
  },
  firebase: {
    apiKey: "AIzaSyCYmvSNMbxsYk5vU_R8iBSkv8qDlanva3Y",
    authDomain: "search-the-odds.firebaseapp.com",
    projectId: "search-the-odds",
    storageBucket: "search-the-odds.appspot.com",
    messagingSenderId: "453628439105",
    appId: "1:453628439105:web:6c1fea23cfdf5d3bd5b4c0",
    measurementId: "G-4PQMH4DV8M"

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
